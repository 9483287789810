<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar :options="calendarOptions" class="full-calendar" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "sidebarToggle, prev,next, title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        events: (info, successCallback) => {
          const _ = this;
          _.$axios.get(`events/calendar`).then((res) => {
            const consume = res.data;
            successCallback(consume.data);
          });
        },
        eventClassNames({ event: calendarEvent }) {
          const calendarsColor = {
            primary: "primary",
            sucess: "success",
            danger: "danger",
            warning: "warning",
            info: "info",
          };

          // eslint-disable-next-line no-underscore-dangle
          const colorName =
            calendarsColor[calendarEvent._def.extendedProps.color];

          return [
            // Background Color
            `bg-light-${colorName}`,
          ];
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
