<template>
  <div>
    <b-card>
      <marquee style="font-size: 28px; color: black; font-weight: bold">{{
          text
      }}</marquee>
    </b-card>

    <b-row v-if="roleName == 'parent' || roleName == 'student'">
      <b-col lg="4" sm="6">
        <statistic-card-horizontal icon="AwardIcon" :statistic="paidStatus" statistic-title="Status Pembayaran" />
      </b-col>
      <b-col lg="4" sm="6">
        <statistic-card-horizontal icon="ActivityIcon" color="success" :statistic="ipsStatus" statistic-title="IPS" />
      </b-col>
      <b-col lg="4" sm="6">
        <statistic-card-horizontal icon="ActivityIcon" color="danger" :statistic="ipkStatus" statistic-title="IPK" />
      </b-col>
    </b-row>

    <b-card v-if="roleName != 'parent' && roleName != 'student'" text-variant="center" :img-src="require('@/assets/images/banner/banner.svg')" overlay img-alt="card img">
      <b-card-title class="centered">
        <h1 style="font-size: 1.8vw" class="mb-1 mt-50 text-primary font-weight-bolder">
          Selamat Datang, {{ name }}
        </h1>
        <h5 style="font-size: 1vw" class="mt-50 text-primary">
          Saat ini anda berada di SIAKAD FKG-UNJANI
        </h5>
        <h5 style="font-size: 1vw" class="text-primary">
          Dapatkan Informasi mengenai Nilai Mahasiswa, Kehadiran
        </h5>
        <h5 style="font-size: 1vw" class="text-primary">
          dan Kuesioner dengan mudah.
        </h5>
      </b-card-title>
    </b-card>

    <calendar></calendar>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BAvatar,
  BCardText,
  BCardTitle,
  BLink,
  BRow,
  BCol,
} from "bootstrap-vue";
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import Calendar from "@/components/Calendar";

export default {
  components: {
    BCard,
    BImg,
    BAvatar,
    BCardText,
    BCardTitle,
    BLink,
    BRow,
    BCol,
    StatisticCardHorizontal,
    Calendar,
  },
  data() {
    return {
      name: "",
      roleName: "",
      cardApi: "/student-grades/student-gpa",
      paidStatus: null,
      ipsStatus: null,
      ipkStatus: null,
      lineChart: {
        series: [
          {
            name: "line",
            data: [365, 390, 365, 400, 375, 400],
          },
        ],
      },
      text: null,
    };
  },
  mounted() {
    const _ = this;
    const account = JSON.parse(localStorage.getItem("account"));
    _.name = account.name;
    _.roleName = account.role?.name;
    
    if (_.roleName == 'parent' || _.roleName == 'student') {
      _.getStatus();
    }

    _.loadDataRunningTex();
  },
  methods: {
    loadDataRunningTex() {
      const _ = this;
      _.$axios.get(`events/broadcast`).then((res) => {
        const consume = res.data;
        _.text = consume.data;
      });
    },
    getStatus() {
      const _ = this;
      _.$axios.get(_.cardApi).then((res) => {
        _.paidStatus = _.paidStatusMap(res.data.data?.paidStatus);
        _.ipsStatus = res.data.data?.accumulation.ips;
        _.ipkStatus = res.data.data?.accumulation.ipk;
      });
    },
    paidStatusMap(status) {
      if (status == "unpaid") {
        status = "Belum Bayar";
      } else if (status == "comply") {
        status = "Memenuhi 50%";
      } else if (status == "paid") {
        status = "Lunas";
      } else if (status == null) {
        status = "Tidak ada";
      } else if (status == 'under-comply'){
        status = "Kurang Dari 50%";
      }

      return status;
    },
  },
};
</script>

<style>
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
